<template>
    <div class="order">
        <div class="search">
            <div class="a-inline-block">
                <label for="">订单编号</label><a-input v-model="osn" size="large" placeholder="请输入订单编号" />
            </div>
            <div class="a-inline-block">
                <label for="">客户名称</label><a-input v-model="name" size="large" placeholder="请输入客户名称" />
            </div>
            <div class="a-inline-block2">
                <label class="info" for="">退款时间</label>
                <a-range-picker v-model="date" size="large" format="YYYY-MM-DD" :placeholder="['开始时间', '结束时间']" />
            </div>
            <div class="a-inline-block">
                <a-button size="large" type="primary" @click="onSearch">搜索</a-button>
                <a-button size="large" @click="reset">重置</a-button>
            </div>
            <div style="text-align:right;">
                <a-button style="width:auto;" size="large" type="primary" @click="delAll">批量删除</a-button>
            </div>
        </div>
        <a-table :row-selection="rowSelection" :rowKey='record=>record.id' :columns="columns" :data-source="data" class="table" id="myTable" :pagination="pagination">
            <span slot="num" slot-scope="text, record, index">
                {{(pagination.current-1)*pagination.pageSize+parseInt(index)+1}}
            </span>
            <template slot="Action" slot-scope="text,record">
                <!-- <a class="action" href="javascript:;" @click="showDetail(record.id)">查看</a> -->
    
                <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(record.id)">
                    <a class="action del" href="javascript:;"  v-if="record.o_status != '已完成' && record.o_status != '关闭'">删除</a>
                </a-popconfirm>
            </template>
        </a-table>
    </div>
</template>
<script>
import $ from "jquery";
import {requestXml,getDateTime} from '../../../assets/js/request';
export default {
    data() {
        return {
            // 订单编号
            osn: "",
            // 客户名称
            name: "",
            // 开始时间
            start: "",
            // 结束时间
            end: "",
            // 签订时间 日期选择框
            date: [],

            methodArr: ["公户打款","微信","支付宝","刷卡","现金"],
            columns:[
                { title: '序号', dataIndex: 'num', width: 70, align: 'center',
                    scopedSlots: { customRender: 'num' },
                },
                { title: '订单编号', dataIndex: 'osn'},
                { title: '客户名称', dataIndex: 'name'},
                { title: '跟进人', dataIndex: 'staff_uid' },
                { title: '金额', dataIndex: 'amount' },
                // { title: '状态', dataIndex: 'status' },
                { title: '退款批次', dataIndex: 'batch'},
                { title: '退款时间', dataIndex: 'receipt_time' },
                { title: '支付方式', dataIndex: 'method' },
                {
                    title: '操作',
                    key: 'operation',
                    fixed: 'right',
                    width: 200,
                    scopedSlots: { customRender: 'Action' },
                }
            ],
            orderStatus: ["关闭","新建","进行中","已完成"],
            data:[],
            selectedRows:[],
            rowSelection:{
                    onSelect: (record, selected, selectedRows) => {
                        console.log(record, selected, selectedRows);
                        this.selectedRows=selectedRows;
                    },
                    onSelectAll: (selected, selectedRows, changeRows) => {
                        console.log(selected, selectedRows, changeRows);
                        this.selectedRows=selectedRows;
                    },
                },

            // 分页
            pagination: {
                current: 1,
                total: 100,
				pageSize: 10, // 默认每页显示数量
				showSizeChanger: true, // 显示可改变每页数量
				pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
				// showTotal: total => `Total ${total} items`, // 显示总数
                onChange: (current) => this.changePage(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize)
                },
			},
            selectedRowKeys: []
        };
    },
    mounted: function(){
        this.getRecord();
    },
    methods: {
        // 搜索
        onSearch(){
            this.pagination.current=1;
            if(this.date.length != 0){
                this.date[0]._d.setHours(0);
                this.date[0]._d.setMinutes(0);
                this.date[0]._d.setSeconds(0);

                this.date[1]._d.setHours(23);
                this.date[1]._d.setMinutes(59);
                this.date[1]._d.setSeconds(59);
                this.start = this.date[0]._d.getTime();
                this.end = this.date[1]._d.getTime();
            }else{
                this.start = "";
                this.end = "";
            }
            this.getRecord();
        },
        reset(){
            this.osn = "";
            this.name = "";
            this.date = [];
            this.start = "";
            this.end = "";
            this.status = null;
            this.getRecord();
        },
        getRecord() {
            requestXml("/Scrm/Order/getRefundList","POST",(res) => {
                for(let i=0;i<res.list.length;i++){
                    res.list[i].receipt_time = getDateTime(res.list[i].receipt_time);
                    res.list[i].created = getDateTime(res.list[i].created);
                    
                    res.list[i].method = this.methodArr[res.list[i].method-1];
                    res.list[i].o_status = this.orderStatus[Number(res.list[i].o_status)];
                }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
                this.data = res.list;

                var tr = document.createElement("tr");
                tr.setAttribute("id","mytr");
                tr.innerHTML = `<td style="width:70px">本页合计</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>`+ res.sum.page.amount +`</td>`;

               
                var tr2 = document.createElement("tr");
                tr2.setAttribute("id","mytr2");
                tr2.innerHTML = `<td>全部合计</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>`+ res.sum.all.amount +`</td>`;

                // if($("#mytr").length > 0) {
                    $("#mytr").remove();
                    $("#mytr2").remove();
                // }else{
                    this.$nextTick(
                        function(){
                            $("#myTable").find(".ant-table-body").find("table").find("tbody").append(tr);
                            $("#myTable").find(".ant-table-body").find("table").find("tbody").append(tr2);
                        }
                    )
                // }
            },{"osn": this.osn,"name": this.name,"start": this.start,"end": this.end,"status": this.status,"page":{"curpage":this.pagination.current,"pagesize":this.pagination.pageSize}})
        },
        // 分页
        changePage(current){
            //current参数表示是点击当前的页码，
            console.log(current)
            this.pagination.current = current;
            this.getRecord(); //向后端发送请求
        },
        showSizeChange(current, pageSize) {
            console.log(current, pageSize)
            this.pagination.current = current;
            this.pagination.pageSize = pageSize;
            this.getRecord(); //向后端发送请求
        },
        // // 查看
        // showDetail(id){
        //     this.$router.push({
        //         path: "/scrm_pc/order/orderDetail",
        //         query: {
        //             id: id,
        //         }
        //     });
        // },
        // 删除
        onDelete(id){
            requestXml("/scrm/Order/delRefund","POST",(res) => {
                console.log(res)
                this.getRecord();
            },{"id": id})
        },
        delAll(){
               if(this.selectedRows.length<1){
                this.$message.info('请先选择');
                return false;
            }else{
                let idArr=[];
                let sel=this.selectedRows;
                for(let i=0;i<sel.length;i++){
                    idArr.push(sel[i].id);
                }
                this.onDelete(idArr);
            }
        },
    },
    
};
</script>
<style scoped>
    .order .search .ant-calendar-picker{
        width: 300px !important;
    }
    .action{
        display: inline-block;
        padding: 5px 10px;
        margin: 0 5px;
        background: #DCEEFF;
        border: 1px solid #36A3FF;
        color: #36A3FF;
        border-radius: 5px;
    }
    .del{
        background: #FFDCDC;
        border-color: #FF4C4C;
        color: #FF4C4C;
    }
</style>

